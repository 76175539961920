import React, { Component } from 'react';


export class Home extends Component
{
	render()
	{
		return (
			<React.Fragment>

<section className="background" style={{position: 'relative', height: '100%'}}>

	<div className="fixed gradient"></div>

	<br/><br/><br/>

	<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col">
			<div>
				<div class="uppercase">
					<h1>The Psalms</h1>
					<h2>for</h2>
					<h1>Singing</h1>
				</div>

				<div class="line"></div>

				<h2>A 21st Century Edition</h2>

				<br/><br/><br/><br/>

				{/* <img src="/img/app-store.svg" /> */}
			</div>
		</div>

		<div className="col-md-auto">
			<img src="/img/iPhone-images.png" className="img-fluid" />
		</div>
	</div>
	</div>

</section>

{/* <p class="heading">About</p>

blah blah

<p class="heading">Features</p> */}


{/* <font style="font-size: 30px; font-weight: lighter">Lighter</font><br/>
<font style="font-size: 30px; font-weight: normal">Regular</font><br/>
<font style="font-size: 30px; font-weight: bold">Bold</font><br/> */}

			</React.Fragment>
		);
	}
}

export default Home;